import { children, createMemo, createUniqueId, Show } from "solid-js";
import { Portal } from "solid-js/web";
import { createPreventScroll } from "@solid-aria/overlays";
import { useStackingContext } from "~/contexts/StackingContext";
import type { JSXElement } from "solid-js";

import IconClose from "~/img/icons/close.svg";

import "./ContactPhone.css";
import "./Dialog.css";

type DialogProps = {
  title?: string;
  children: string | JSXElement;
  horizontalAlignment?: string;
  verticalAlignment?: string;
  offsetTop?: number;
  width?: number;
  additionalClass?: string;
  /** SignalSetter */
  closingCallback: (v: boolean) => void;
};

export default function Dialog(props: DialogProps) {
  const resolvedChildren = children(() => props.children);
  const [
    ,
    { incrementPreventScrollLayerCount, decreasePreventScrollLayerCount },
  ] = useStackingContext();

  const dialogID = createUniqueId();

  const closeDialog = async () => {
    const dialog = document.getElementById(dialogID);
    dialog!.classList.add("disabled");
    await new Promise((r) => setTimeout(r, 300));
    props.closingCallback(false);
  };

  if (incrementPreventScrollLayerCount) {
    incrementPreventScrollLayerCount();
  }
  createPreventScroll();

  const additionalClass = createMemo(() => {
    return props.additionalClass ? " " + props.additionalClass : "";
  });

  return (
    <>
      <Portal mount={document.getElementById("dialog-receiver")!}>
        <div class="dialog" id={dialogID}>
          <div
            class="cog-overlay"
            onClick={() => {
              closeDialog().then(() => {
                decreasePreventScrollLayerCount!();
              });
            }}
          />
          <div
            class="dialog-container"
            style={{
              "align-items": props.verticalAlignment ?? undefined,
              "justify-content": props.horizontalAlignment ?? undefined,
            }}
          >
            <div
              class={`dialog-inner` + additionalClass()}
              data-test="modal"
              style={{
                top: props.offsetTop ? props.offsetTop + "px" : undefined,
                "max-width": props.width ? props.width + "px" : undefined,
                "max-height": props.offsetTop
                  ? "calc(90% - " + props.offsetTop + "px)"
                  : undefined,
              }}
            >
              <div class="headline">
                <Show when={props.title}>
                  <h2>{props.title}</h2>
                </Show>
                <button
                  type="button"
                  class="btn btn-icon"
                  aria-label="Fermer"
                  onClick={() => {
                    closeDialog().then(() => {
                      decreasePreventScrollLayerCount!();
                    });
                  }}
                  data-test="modal-close"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconClose />
                  </i>
                </button>
              </div>
              <Show
                fallback={
                  <div class="dialog-content">{resolvedChildren()}</div>
                }
                when={typeof resolvedChildren() === "string"}
              >
                <div
                  class="dialog-content"
                  innerHTML={resolvedChildren() + ""}
                />
              </Show>
            </div>
          </div>
        </div>
      </Portal>
    </>
  );
}
